<template>
  <div
    id="uploadTemplatePreference"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <h3 class="text-center text-dark fw-normal fs-5 mb-4">
      Select Your Preference
    </h3>
    <el-row type="flex" :gutter="30" justify="center" class="block-types">
      <el-col
        :lg="7"
        :md="10"
        :sm="15"
        :xs="20"
        class="select-your-preference-upload-file"
      >
        <div
          v-loading="loading"
          :element-loading-text="loadingText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="upload-file-container"
        >
          <el-upload
            class="upload-file-el-upload"
            drag
            name="logo"
            action
            :http-request="uploadFile"
            :on-change="uploadFile"
            :show-file-list="false"
            :auto-upload="false"
            accept=".doc, .pdf, .docx, .txt, .png, .jpg, .jpeg, .xls, .xlsx, .xlsm,"
            multiple
          >
            <div class="upload-file">
              <div class="icon py-4">
                <img
                  src="@/assets/img/icons/upload-file.svg"
                  alt="Upload File"
                  height="40"
                />
              </div>
              <h4 class="fw-normal blue pb-2">Upload A File</h4>
              <p class="text-dark fs-9 mb-0">Drag &amp; Drop files here</p>
              <p class="text-muted fs-9 opacity-60 upload-a-file-bottom-text">
                File Supported: PDF,DOC,JPG,PNG,TXT
              </p>
            </div>
          </el-upload>
          <div
            class="upload-from-other-container"
            v-if="getAccess('documents', 'blank_doc', '')"
          >
            <el-button class="mt-1" type="danger" @click="chooseBlankDocument"
              >Blank template</el-button
            >
          </div>
          <span v-if="logoError">{{ logoError }}</span>
        </div>
      </el-col>
      <el-col :lg="7" :md="10" :sm="15" :xs="20">
        <div
          class="option option-card aiContainer"
          :style="!selectedAIOption ? 'height:336px' : ''"
          @click="selectOption()"
        >
          <div
            class="icons star-symbol"
            :style="!selectedAIOption ? 'height:20%;margin-top:20%' : ''"
          >
            <img class="mb-1 mt-1" src="@/assets/AI Icon.svg" />
          </div>
          <div class="Title">Create with AI</div>
          <div class="description">
            Provide some details, and AI will create a complete template for you
            using an editor.
          </div>
          <div v-if="selectedAIOption" class="template-details mt-1">
            <label class="mb-1">Template Name </label
            ><span class="text-danger">*</span>
            <el-input
              v-model="editorTemplateName"
              class="mt-1"
              placeholder="ex:- Offer Letter"
              @keydown.enter.native.prevent="onContinueToCkeditor"
            ></el-input>
          </div>
          <el-button
            v-if="editorTemplateName"
            class="buttonSty mt-1"
            @click="onContinueToCkeditor()"
            type="primary"
            >Create Template</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="30" justify="center" class="name-types">
      <el-col :span="14">
        <draggable v-if="this.files.length" v-model="files">
          <transition-group tag="div" name="flip-list" id="field-group">
            <div
              class="filename"
              v-for="(file, index) in files"
              :key="JSON.stringify(file)"
            >
              <a class="el-upload-list__item-name">
                <img
                  :src="require('@/assets/img/icons/upload-active.svg')"
                  alt="icon"
                  class="img-active"
                />
                <img
                  v-if="
                    file.raw.type == 'image/jpeg' ||
                    file.raw.type == 'image/jpg'
                  "
                  :src="require('@/assets/img/icons/JPG.svg')"
                  alt="icon"
                  class="img-fluid"
                />
                <img
                  v-else-if="file.raw.type == 'image/png'"
                  :src="require('@/assets/img/icons/PNG.svg')"
                  alt="icon"
                  class="img-fluid"
                />

                <img
                  v-else
                  :src="require('@/assets/img/icons/pdf.svg')"
                  alt="icon"
                  class="img-fluid"
                />
                {{ file.name }}
              </a>
              <div class="direction-buttons">
                <el-button
                  type="text"
                  icon="el-icon-bottom"
                  class="directions"
                  @click="moveDown(index)"
                >
                </el-button>
                <el-button
                  type="text"
                  icon="el-icon-top"
                  class="directions"
                  @click="moveUp(index)"
                >
                </el-button>
                <a @click="deleteItem(index)">
                  <i class="el-icon-circle-close"></i>
                </a>
              </div>
            </div>
          </transition-group>
        </draggable>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      :gutter="30"
      justify="center"
      v-if="files && files.length > 1"
      class="mt-1"
    >
      <el-col :span="14">
        <el-checkbox v-model="isMergeDocument"
          >Process it as a single document?</el-checkbox
        >
      </el-col>
    </el-row>
    <!-- v-if="!this.$route.query.group" -->
    <el-row
      type="flex"
      :gutter="30"
      justify="center"
      v-if="files && files.length && !this.$route.query.group"
      class="mt-1"
      style="text-align: center"
    >
      <el-col :span="20">
        <span>Select Groups &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <el-select
          v-model="groupsData"
          placeholder="Select Group"
          multiple
          filterable
          collapse-tags
        >
          <el-option
            v-for="(item, index) of getAllEntityGroupsData.data"
            v-bind:key="index"
            :label="item.name"
            :value="item._id"
          ></el-option>
        </el-select>
        <el-tooltip content="Add New Group" placement="top" effect="light">
          <i class="el-icon-circle-plus-outline" @click="addGroupPopUP"></i>
        </el-tooltip>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="30" justify="center">
      <el-col :span="6" :offset="4">
        <div v-if="files.length > 0">
          <el-button
            type="danger"
            class="text-center upload-btn"
            @click="checkForMergeOrUpload"
            >Next</el-button
          >
        </div>
      </el-col>
    </el-row>
    <dialog-component
      :visible="blanckDocumentModal"
      :title="'Upload a blank template'"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="resetBlankTemplate"
    >
      <div v-loading="blanckDocumentData.loading">
        <span>Enter template name</span>
        <el-input
          placeholder="Enter template name"
          v-model="blanckDocumentData.name"
          class="mb-2 mt-1"
        ></el-input>
        <span>Select number of pages</span>
        <el-select
          placeholder="Select number of pages"
          v-model="blanckDocumentData.pages"
          style="width: 100%"
          class="mt-1"
        >
          <el-option
            v-for="(i, index) in [1, 2, 3]"
            :key="index"
            :label="i"
            :value="i"
          ></el-option>
        </el-select>
        <el-row
          :gutter="30"
          justify="center"
          v-if="!this.$route.query.group"
          class="mt-1"
        >
          <el-col :span="20">
            <span>Select Groups &nbsp;&nbsp;&nbsp;&nbsp;</span>
            <br />
            <el-select
              v-model="groupsData"
              placeholder="Select Group"
              multiple
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) of getAllEntityGroupsData.data"
                v-bind:key="index"
                :label="item.name"
                :value="item._id"
              ></el-option>
            </el-select>
            <el-tooltip content="Add New Group" placement="top" effect="light">
              <i class="el-icon-circle-plus-outline" @click="addGroupPopUP"></i>
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <el-button @click="resetBlankTemplate" plain>Cancel</el-button>
        <el-button
          :disabled="!blanckDocumentData.name.trim()"
          type="primary"
          @click="uploadBlanckDocument"
          >Continue</el-button
        >
      </span>
    </dialog-component>

    <!-- Adding New Group Dialog start -->
    <dialog-component
      title="Add New Group"
      :visible="showAddGroupDialog"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="addGroupPopUP = false"
    >
      <el-form>
        <div v-loading="loadingSaveGroup">
          <el-form-item class="mb-10" label="Group Title">
            <el-input
              type="text"
              v-model="groupName"
              placeholder="Enter Title"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button @click="showAddGroupDialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="saveGroup"
          :disabled="!groupName || !groupName.trim()"
          class="px-20"
          ><i class="el-icon-check"></i> Save Group
        </el-button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="alertInfo"
      :title="'Alert'"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="alertInfo = false"
      v-draggable
    >
      <!-- <span v-if="!checkIsBlankModal"
        >Would you like to continue as a Word document or a PDF?"</span
      > -->
      <span v-if="checkIsBlankModal"
        >Would you like to create your own template using an editor or start
        with a blank template?</span
      >
      <span slot="footer" style="display: flex; position: relative; left: 10px">
        <el-button
          v-if="checkIsBlankModal"
          primary
          class="btn"
          @click="continueUsingEditor()"
          >Continue with Editor</el-button
        >
        <el-button
          v-if="checkIsBlankModal"
          type="primary"
          @click="continueAsBlankTemplate()"
          style="padding: 10px"
          >Continue as Blank Template</el-button
        >
        <!-- <el-button
          v-if="!checkIsBlankModal"
          primary
          @click="continueAsWordFile()"
          >Continue as word document</el-button
        >
        <el-button
          v-if="!checkIsBlankModal"
          type="primary"
          @click="convertToPdf()"
          >Convert to PDF</el-button
        > -->
      </span>
    </dialog-component>
    <dialog-component
      :visible="editorModal"
      :title="'Template Name'"
      :containerWidth="getIsMobile ? '100%' : '20%'"
      @before-close="handleClose"
      v-draggable
    >
      <el-input
        v-model="editorTemplateName"
        placeholder="ex:-Offer Letter"
        style="padding: 15px"
      ></el-input>
      <span slot="footer">
        <el-button danger @click="onContinueToCkeditor()"
          >Continue To Editor</el-button
        >
      </span>
    </dialog-component>
    <!-- Adding New Group Dialog end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
// import axios from "@/config/axios";
import draggable from "vuedraggable";
import appConfig from "@/config/app";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import AccessManagement from "@/mixins/AccessManagement";
export default {
  name: "companyDocuments-UploadDocumentPreference",
  components: {
    draggable,
  },
  mixins: [
    ComanyDocumentsPresignedURLsHelper,
    DocumentsHelper,
    AccessManagement,
  ],
  data() {
    return {
      selectedAIOption: false,
      htmlContent: "",
      loadingText: "Fetching data",
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
      },
      disabledButton: false,
      dialogVisible: false,
      loading: false,
      logoError: "",
      checkIsBlankModal: false,
      alertInfo: false,
      editorTemplateName: "",
      editorModal: false,
      files: [],
      fileUploadUrls: [],
      fileNames: [],
      isMergeDocument: false,
      fileUploadData: "",
      blanckDocumentModal: false,
      blanckDocumentData: {
        name: "",
        pages: 1,
        loading: false,
      },
      blankTemplatesUrl: [
        "/rest/esignature/files/3cc3b9ac-ff3d-4dfa-90a9-96644c719a15.pdf",
        "/rest/esignature/files/dfc42fc5-4696-4e88-81df-8daf1a7a63dd.pdf",
        "/rest/esignature/files/56647615-6ccc-49c6-9991-b72be2706148.pdf",
      ],
      pdfFormFields: {},
      pageCount: 0,
      currentDoc: 0,
      groupsData: [],
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false,
    };
  },
  computed: {
    ...mapGetters("documents", [
      "getFileUploadURL",
      "getCompanyDocumentAddStatus",
      "getNewCompanyDocument",
      "getCreateConfigureDocumentData",
    ]),
    ...mapGetters("documentOperations", ["getPresignedURLsData"]),
    ...mapGetters("s3FileUpload", ["getDocumentUploadStatus"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAuthenticationDetails",
      "getActiveWorkspace",
    ]),
    ...mapGetters("fileUpload", [
      "getUploadFileDataStatus",
      "getUploadedPdfData",
      "getCopyDocumentStatus",
      "getCopyDocumentUrl",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("entityGroups", [
      "getAllEntityGroupsData",
      "getSingleEntityGroupData",
      "getEntityGroupErrors",
      "getError",
    ]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token,
      };
    },
  },
  // beforeDestroy() {
  //   this.clearFormData();
  // },
  methods: {
    selectOption() {
      this.selectedAIOption = true;
    },
    async fetchCompanyGroups() {
      try {
        await this.$store.dispatch("entityGroups/fetEntityGroupsData", {
          get_all: true,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchAssignedGroupsByRoleId() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        await this.$store.dispatch(
          "roles/fetchRoleById",
          this.getAuthenticatedUser.activeRole.role_id
        );
        if (this.getRoleById && this.getRoleById) {
          this.groupsData = this.getRoleById.allowed_groups;
        } else {
          this.groupsData = [];
        }

        console.log(this.getRoleById);
      }
    },
    async addGroupPopUP() {
      this.showAddGroupDialog = true;

      await this.$store.dispatch("entityGroups/resetErrors");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      await this.$store.dispatch("entityGroups/addEntityGroup", {
        name: this.groupName,
        description: "",
        docTemplates: [],
      });
      if (
        this.getSingleEntityGroupData &&
        this.getSingleEntityGroupData.success
      ) {
        this.fetchCompanyGroups();
        this.loadingSaveGroup = false;
        this.groupName = "";
        this.showAddGroupDialog = false;
        this.groupsData.push(this.getSingleEntityGroupData.data._id);
      } else if (this.getError && !this.getError.success) {
        this.loadingSaveGroup = false;
        let msg =
          this.getError && this.getError.message
            ? this.getError.message
            : "Something went wrong";
        this.$message.warning(msg);
      }
    },
    async uploadBlanckDocument() {
      this.loading = true;
      this.blanckDocumentData.loading = true;
      this.fieldsFormUpload.title = this.blanckDocumentData.name;
      let selectedFile =
        this.blankTemplatesData[this.blanckDocumentData.pages - 1];

      const copyDocumentData = await this.getCopyDocumentsdata(selectedFile);
      if (copyDocumentData) {
        this.fileUploadUrl = copyDocumentData.path; //
        this.fileUploadUrls = [copyDocumentData.path];

        this.fileNames = [this.blanckDocumentData.name];
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.blanckDocumentData.loading = false;
        this.addCompanyDocument();
      } else {
        this.logoError = "Error in uploading file. Please try again..";
      }

      this.blanckDocumentModal = false;
    },
    async handleFileChange(file) {
      console.log("-=-=tst", file);
    },
    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });
    },
    resetBlankTemplate() {
      this.blanckDocumentModal = false;
      this.blanckDocumentData.name = "";
      this.blanckDocumentData.pages = 1;
    },
    chooseBlankDocument() {
      /*  it will be commented out untill the ckeditor code is updated */
      // this.checkIsBlankModal = true;
      // //this.files.forEach((file) => {
      // this.showConversionPrompt();
      // });
      /* upto here */
      this.blanckDocumentModal = true;
    },
    // chooseBlankDocument() {
    //   this.blanckDocumentModal = true;
    // },
    moveDown(index) {
      if (index == this.files.length - 1) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;

      return signaturedUsers;
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    async checkForMergeOrUpload() {
      /** MIXIN **/
      this.loading = true;
      // const allowedTypes = [
      //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
      //   "application/msword", // .doc
      //   "application/vnd.openxmlformats-officedocument.wordprocessingml.template", // .dotx
      //   "application/vnd.ms-word.document.macroEnabled.12", // .docm
      //   "application/vnd.ms-word.template.macroEnabled.12", // .dotm
      //   "application/rtf", // .rtf
      //   "application/vnd.oasis.opendocument.text", // .odt
      //   // Add more MIME types for other document formats as needed
      // ];
      // if (
      //   allowedTypes.includes(this.files[0].raw.type) &&
      //   this.files.length === 1
      // ) {
      //   this.files.forEach((file) => {
      //     this.showConversionPrompt(file);
      //   });
      // } else {
      await this.mergeOrUploadFile();
      //}
      await this.fetchAllDocuments();
      this.loading = false;
    },
    async showConversionPrompt(file) {
      console.log("File before processing:", file);
      this.alertInfo = true;
    },
    continueUsingEditor() {
      this.alertInfo = false;
      if (this.files && this.files.length) {
        this.editorTemplateName = this.files[0].name;
        this.editorModal = false;
        this.onContinueToCkeditor();
      } else {
        this.editorModal = true;
      }
    },
    async onContinueToCkeditor() {
      if (this.editorTemplateName.trim() === "") {
        this.$message.error("Please enter the template name!.");
      } else {
        this.loading = true;
        this.loadingText = "Redirecting to the editor screen using AI...";
        await this.addCompanyDocumentWord(this.editorTemplateName);
        this.selectedAIOption = false;
        this.loading = false;
      }
    },
    continueAsWordFile() {
      this.files.forEach((file) => {
        //  eventBus.$emit("file-uploaded", file);
        this.addCompanyDocumentWord(file);
      });
      this.alertInfo = false;
    },
    async addCompanyDocumentWord(file) {
      try {
        this.loading = true;
        let params = {
          title: typeof file === "string" ? file : file.name,
          file_names:
            typeof file === "string"
              ? [file]
              : file.name
              ? [file.name]
              : [file.name],
          isCkEditorDocument: true,
          //editorContent:''
        };
        await Promise.all([
          this.$store.dispatch("documents/addCompanyDocument", params),
          //  this.$store.dispatch("documentOperations/removePdfFields", {pdfUrls: this.fileUploadUrls})
        ]);
        let isAEditorDocument = true;
        if (this.getCompanyDocumentAddStatus) {
          await this.uploadSelectedDocument(isAEditorDocument);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async convertToPdf() {
      await this.mergeOrUploadFile();
      this.alertInfo = false;
    },
    async continueAsBlankTemplate() {
      this.blanckDocumentModal = true;
      this.alertInfo = false;
    },
    async uploadSingleFile(tempUrl) {
      try {
        this.loading = true;

        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
        });

        this.fileUploadUrl = tempUrl[0];
        this.fileUploadUrls = tempUrl;
        this.fileNames = this.files.flatMap((e) => e.name);
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.addCompanyDocument();
      } catch (err) {
        this.loading = false;
      }
    },
    async mergeAllDocs() {
      try {
        this.loading = true;
        var formData = new FormData();
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
          formData.append(
            "uploadedFiles",
            file.raw !== undefined ? file.raw : file
          );
        });
        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        let params = {
          data: formData,
          companyId: companyId,
        };
        await this.$store.dispatch("fileUpload/uploadUserDocxFilev4", params);
        this.loading = false;
        if (this.getUploadFileDataStatus) {
          let tempUrl = "";
          if (
            this.getUploadFileDataStatus.url.includes(appConfig.S3_BUCKET_URL)
          ) {
            tempUrl = this.getUploadFileDataStatus.url.replace(
              appConfig.S3_BUCKET_URL,
              ""
            );
          } else {
            tempUrl = this.getUploadFileDataStatus.url;
          }
          this.fileUploadUrl = tempUrl;
          this.fileUploadUrls = [tempUrl];
          this.fileNames = [this.fieldsFormUpload.title];
          this.disabledButton = false;
          this.isFileIsUploaded = true;
          this.addCompanyDocument();
        } else {
          this.logoError = "Error in uploading file. Please try again..";
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async addCompanyDocument() {
      try {
        this.loading = true;
        let params = this.prepareParams();

        if (!params) {
          this.$message.error("Please select a document");
          return;
        }
        await Promise.all([
          this.$store.dispatch("documents/addCompanyDocument", params),
          this.$store.dispatch("documentOperations/removePdfFields", {
            pdfUrls: this.fileUploadUrls,
          }),
        ]);
        let isAEditorDocument = false;
        if (this.getCompanyDocumentAddStatus) {
          await this.uploadSelectedDocument(isAEditorDocument);
          bus.$emit("document-added", this.fieldsFormUpload.type);
          this.$emit("document-added", this.fieldsFormUpload.type);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    prepareParams() {
      let params = {};
      let originalUrls =
        this.getPresignedURLsData &&
        this.getPresignedURLsData.original_doc_paths;
      params.file_upload_url = this.fileUploadUrl;
      params.file_upload_urls = this.fileUploadUrls;
      params.file_original_download_urls = originalUrls;
      params.title = this.fieldsFormUpload.title;
      params.type = this.fieldsFormUpload.type;
      params.file_names = this.fileNames;
      // params.groups = this.groupsData
      //   ? this.groupsData
      //   : this.$route.query.group;
      if (this.groupsData && this.groupsData.length > 0) {
        params.groups = this.groupsData;
      } else {
        params.groups = this.$route.query.group
          ? [this.$route.query.group]
          : [];
      }
      return params;
    },

    handleClose() {
      this.dialogVisible = false;
      this.alertInfo = false;
      this.editorModal = false;
      this.checkIsBlankModal = false;
    },
    async beforeLogoUpload(file) {
      console.log(file);
    },

    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("documents/fetchDocumentUploadUrl", data);
    },
    async uploadFile(file) {
      console.log(file);
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          this.files.push(file);
        } else {
          this.$message.error("Large file.Maximum upload file size : 25 MB!");
        }
      } else {
        this.$message("File format is not supported");
      }
      //   try {
      //     var formData = new FormData();
      //     formData.append('file', file.raw, file.name);
      //     console.log('-=-=-=-',formData)
      //   const response = await axios.post('/convert-to-html', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //     },
      //   });
      //   console.log(response)
      //   this.htmlContent = response.data.htmlContent;
      // } catch (error) {
      //   console.error('Error uploading and converting file:', error);
      // }
    },
    async uploadSelectedDocument(data) {
      this.loading = true;
      this.loadingText =
        data && data === true
          ? "Redirecting to the editor screen using AI..."
          : "Uploading Document...";
      if (this.getNewCompanyDocument) {
        let is_template = true;
        await this.$store.dispatch(
          "documents/fetchConfigureDocumentIdByDocumentId",
          this.getNewCompanyDocument._id,
          is_template
        );
        if (this.getCreateConfigureDocumentData) {
          this.$notify.success({
            title: "Success",
            message: "Template created successfully",
          });
          let obj = {};
          obj[this.getCreateConfigureDocumentData.configurable_document._id] =
            this.pdfFormFields;
          await this.$store.commit("documents/setDocumentFormFieldsData", obj, {
            root: true,
          });
          this.$router.push({
            name: "edit-configure-template-image",
            params: {
              document_id: this.getNewCompanyDocument._id,
              configurable_document_id:
                this.getCreateConfigureDocumentData.configurable_document._id,
            },
            query: this.$route.query,
          });
        } else {
          this.$message("Sorry ! Error while creating configurable document");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.loading = false;
    },
    cancel() {
      this.$emit("document-cancelled");
    },
    clearFormData() {
      this.fieldsFormUpload = {
        title: "",
        description: "",
        type: "",
        category: "",
        groups: [],
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
        e_signature_value: "",
        e_signature: "",
      };
    },
  },
  async mounted() {
    this.fetchCompanyGroups();
    this.fetchAssignedGroupsByRoleId();
  },

  beforeDestroy() {
    this.$store.commit("documents/setFileUploadURL", null, { root: true });
    this.$store.commit("documents/setCompanyDocumentAddStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setCreateConfigureDocumentData", null, {
      root: true,
    });
    this.$store.commit("s3FileUpload/setDocumentUploadStatus", null, {
      root: true,
    });
    this.$store.commit("fileUpload/setUploadFileDataStatus", null, {
      root: true,
    });
    this.$store.commit("fileUpload/setUploadedPdfData", null, { root: true });
    this.$store.commit("fileUpload/setCopyDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("fileUpload/setCopyDocumentUrl", null, { root: true });
    this.clearFormData();
  },
};
</script>

<style lang="scss">
#uploadTemplatePreference {
  padding-top: 5%;
  .el-dialog__body {
    overflow-y: hidden;
  }
  .block-types {
    .upload-file {
      height: 265px;
      .el-upload.el-upload--text {
        height: inherit;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .el-upload-list__item-name {
      margin-top: 1em;
    }
    .d-block {
      .el-button {
        margin-top: 1em;
        background-color: #efefef;
        text-align: center;
        &:hover {
          h4 {
            color: #f754a2;
          }
          border-color: #f754a2;
        }
      }
    }
  }
  .name-types {
    margin-top: 2%;
    .filename {
      height: 56px;
      margin-top: 1%;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dfe3eb;
      box-sizing: border-box;
      border-radius: 2px;
      .el-upload-list__item-name {
        margin-top: 1em;
        .img-active {
          visibility: hidden;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .img-fluid {
          position: relative;
          top: 5px;
          width: 18.43px !important;
        }
      }
      .direction-buttons {
        display: flex;
        visibility: hidden;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      &:hover {
        .img-active {
          visibility: visible;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .direction-buttons {
          display: flex;
          visibility: visible;
          position: relative;
          top: -35%;
          left: 97%;
          .directions {
            position: relative;
            right: 10%;
            top: -10px !important;
          }
        }
      }
    }
  }
  .upload-btn {
    margin-top: 10px;
    position: relative;
    bottom: 0px;
    align-items: center;
  }
}
.select-your-preference-upload-file {
  display: flex;
  justify-content: end;
  .upload-file-container {
    &:hover {
      border: 1px solid #f754a2;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }
    @media (min-width: 991.98px) {
      min-height: 336px;
    }
    // max-width: 360px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .upload-file-el-upload {
      .block-types {
        .upload-file {
          width: 200px;
          height: 100%;
        }
      }
      .upload-a-file-bottom-text {
        border-bottom: 1px solid #ddd3d3;
        padding: 0 0 5px 0;
        margin: 0px 20px;
      }
      @media (max-width: 991.98px) {
        .el-upload-dragger {
          height: 207px !important;
        }
      }
      .el-upload-dragger {
        border: none;
        width: 360px;
        margin-top: 52px;
      }
    }
    .upload-from-other-container {
      text-align: center;
      .el-upload {
        border: none !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border: none;
        }
      }
      .upload-a-file-upload-from-text {
        margin-top: 10px;
      }
      .from-others {
        display: flex;
        justify-content: center;
        .image {
          cursor: pointer;
          margin-left: 10px;
          margin-right: 10px;
          .upload-icon {
            width: 25px;
            height: 25px;
          }
          @media (max-width: 991.98px) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.el-button.is-plain:hover {
  background: #fff;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.aiContainer {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.aiContainer:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #409eff;
}

.icons img {
  width: 50px;
  height: 50px;
}

.Title {
  font-size: 1.5em;
  margin-top: 10px;
}

.description {
  margin-top: 10px;
  color: #666;
}

.template-details {
  margin-top: 20px;
  text-align: left;
}
.buttonSty {
  margin-top: 10px;
  text-align: center;
}
.star-symbol {
  background: #f5f9ff;
  z-index: 1;
  width: 100%;
  height: 50%;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .Title {
    font-size: 0.9em;
    font-weight: bold;
  }
  .description {
    font-size: small;
  }
}
</style>
